'use strict';

var toStr = Object.prototype.toString;
var fnToStr = Function.prototype.toString;
var isFnRegex = /^\s*(?:function)?\*/;
var hasToStringTag = require('has-tostringtag/shams')();
var getProto = Object.getPrototypeOf;
var getGeneratorFunc = function () {
  // eslint-disable-line consistent-return
  if (!hasToStringTag) {
    return false;
  }
  try {
    return Function('return function*() {}')();
  } catch (e) {}
};
var GeneratorFunction;
module.exports = function isGeneratorFunction(fn) {
  if (typeof fn !== 'function') {
    return false;
  }
  if (isFnRegex.test(fnToStr.call(fn))) {
    return true;
  }
  if (!hasToStringTag) {
    var str = toStr.call(fn);
    return str === '[object GeneratorFunction]';
  }
  if (!getProto) {
    return false;
  }
  if (typeof GeneratorFunction === 'undefined') {
    var generatorFunc = getGeneratorFunc();
    GeneratorFunction = generatorFunc ? getProto(generatorFunc) : false;
  }
  return getProto(fn) === GeneratorFunction;
};